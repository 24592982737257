import React from 'react'
import { Link } from 'react-router-dom'

function Plans() {
    return (
        <div id='plans'>
            <p class="text-center benefits-heading" >
                Our  <span>
                    Plans
                </span>
            </p>
            <section class="benefits">
                <div class="container">
                    <div class=" row align-items-center justify-content-center gap-4">
                        <div class="price-card col-xl-4 col-lg-4 col-md-3 col-sm-12 col-xs-12 ">
                            <p class="price-title">
                                Silver
                            </p>
                            {/* <p class="price-value">
                                $4.50
                            </p> */}
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    For 1 parking
                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    With 2 user access

                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    For all types of parking

                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    Customer Support
                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    Full software support
                                </p>
                            </div>
                            <div class="mx-auto text-center">

                                <Link class="" href=""
                                    to="/contact"
                                >
                                    <button class=" get-btn click-btn btn-style4">  Get Stared
                                    </button>

                                </Link>



                            </div>
                        </div>
                        <div class="price-card Golden-card col-xl-4 col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                            <p class="price-title">
                                Gold
                            </p>
                            {/* <p class="price-value">
                                $9.50
                            </p> */}
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    For 3 to 5 parking

                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    With 6 to 10 user access
                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    For all types of parking
                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    Customer support

                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    Full software support
                                </p>
                            </div>
                            <div class="mx-auto text-center">

                                <Link class="" href=""
                                    to="/contact"
                                >
                                    <button class=" golden get-btn click-btn btn-style4">    Get Stared</button>

                                </Link>

                            </div>
                        </div>
                        <div class="price-card col-xl-4 col-lg-4 col-md-3 col-sm-12 col-xs-12 ">
                            <p class="price-title">
                                Platinum
                            </p>
                            {/* <p class="price-value">
                                $4.50
                            </p> */}
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    For 5+ Parking
                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    With 10+ users

                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    For all types of parking

                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    Customer Support
                                </p>
                            </div>
                            <div class="d-flex value-wrapper align-items-center gap-3 mb-3">
                                <img src="./assets/Tick-Square.png" />
                                <p class="value-text m-0">
                                    Full software support
                                </p>
                            </div>
                            <div class="mx-auto text-center">

                                <Link class="" href=""
                                    to="/contact"
                                >
                                    <button class=" get-btn click-btn btn-style4">       Get Stared</button>

                                </Link>


                            </div>
                        </div>

                    </div>
                </div>

            </section>
            <section class="download">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <div class="download-content-wrapper">
                                <p class="downloadapp-heading">
                                    DOWNLOAD APP &
                                    GET THE <span class="">ParkGo !</span>
                                </p>
                                {/* <p class="get-offer-details">
                                    Get 30% off for first transaction using
                                    Rondovision mobile app for now.
                                </p> */}
                                <div class="d-flex align-items-center gap-4">

                                    <img class="download-img" src="./assets/download2.png" alt="Download App 2" />

                                    <a href="https://parkgoapp.com/app-release.apk" download>
                                        <img class="download-img" src="./assets/download1.png" alt="Download App 1" />
                                    </a>
                                </div>

                            </div>
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12 Vouchers-img-wrapper">
                            <img class="Vouchers-img " src="./assets/aboutsectionimg6.png" alt="download img" />
                            <div class="main">
                                <div class="big-circle">
                                    <div class="icon-block">

                                    </div>
                                    <div class="icon-block">

                                    </div>
                                    <div class="icon-block">

                                    </div>
                                    <div class="icon-block">

                                    </div>
                                </div>
                                <div class="circle">
                                    <div class="icon-block">

                                    </div>
                                    <div class="icon-block">
                                    </div>
                                    <div class="icon-block">
                                    </div>
                                    <div class="icon-block">
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Plans