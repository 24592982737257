import React from 'react'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Pagination } from 'swiper/modules';

function About() {
    return (

        <div id='home'>
            <section className="hero-section">
                <div className='container'>
                <Swiper
                    autoplay={{
                        delay: 2000,
                        pauseOnMouseEnter:true
                    }}
                    centeredSlides={true}
                    loop={true}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    modules={[Autoplay, Pagination]}
                    className="mySwiper"
                >
                    {/* Slide 1 */}
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-6">
                                <div className="main-heading-wrapper">
                                    <h1 className="main-heading">
                                        High Performance<span> In wide area is our Guarantee</span>
                                    </h1>
                                    <p className="para-details">
                                        Aliquam tristique adipiscing ornare nibh netus fringilla nascetur.
                                        Risus proin fames elit a eros tincidunt sagittis. Read More
                                    </p>
                                    <Link to="https://stek.parkgo.justapay.in/login-user/">
                                        <button className="signin-btn">Sign in</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 text-center">
                                <img className="parking-img" src="./assets/slidersection1.png" alt="Slider 1" />
                            </div>
                        </div>
                    </SwiperSlide>

                    {/* Slide 2 */}
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-6">
                                <div className="main-heading-wrapper">
                                    <h1 className="main-heading">
                                        High Performance<span> In wide area is our Guarantee</span>
                                    </h1>
                                    <p className="para-details">
                                        Aliquam tristique adipiscing ornare nibh netus fringilla nascetur.
                                        Risus proin fames elit a eros tincidunt sagittis. Read More
                                    </p>
                                    <Link to="https://stek.parkgo.justapay.in/login-user/">
                                        <button className="signin-btn">Sign in</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 text-center">
                                <img className="parking-img" src="./assets/sidersection2.png" alt="Slider 2" />
                            </div>
                        </div>
                    </SwiperSlide>

                    {/* Slide 3 */}
                    <SwiperSlide>
                        <div className="row">
                            <div className="col-6">
                                <div className="main-heading-wrapper">
                                    <h1 className="main-heading">
                                        High Performance<span> In wide area is our Guarantee</span>
                                    </h1>
                                    <p className="para-details">
                                        Aliquam tristique adipiscing ornare nibh netus fringilla nascetur.
                                        Risus proin fames elit a eros tincidunt sagittis. Read More
                                    </p>
                                    <Link to="https://stek.parkgo.justapay.in/login-user/">
                                        <button className="signin-btn">Sign in</button>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-6 text-center">
                                <img className="parking-img" src="./assets/slider6.png" alt="Slider 3" />
                            </div>
                        </div>
                    </SwiperSlide>
                </Swiper>
                </div>
            </section>






            <section class="about-us">
                <div class="container">
                    <div class="row">

                        <div class="col-xl-6  col-lg-6 col-md-6 col-sm-12 col-xs-12 about-us-img-wrapper ">
                            <img class="about-us-img" src="./assets/about-main-1.png" alt="" />
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                            <p class="about-us-heading text-center">
                                About <span>
                                    Us
                                </span>
                            </p> 
                            <div class="aboutus-content-wrapper">
                                <div className='aboutus-content clearfix'>
                                    <div className='aboutus-icon'>
                                        <img class="" src="./assets/about-icon-1.png" alt="" />
                                    </div>
                                    <div className='aboutus-text'>
                                        <p>Whether you're parking, passing through toll booths, shopping at malls, or refueling your vehicle, our all-in-one FASTag has you covered.</p>
                                    </div>
                                </div>
                                <div className='aboutus-content clearfix'>
                                    <div className='aboutus-icon'>
                                        <img class="" src="./assets/abou-icon-4.png" alt="" />
                                    </div>
                                    <div className='aboutus-text'>
                                        <p>Save time, fuel, and effort with smart parking solutions from ParkGO</p>
                                    </div>
                                </div>

                                <div className='aboutus-content clearfix'>
                                    <div className='aboutus-icon'>
                                        <img class="" src="./assets/about-icon-3.png" alt="" />
                                    </div>
                                    <div className='aboutus-text'>
                                        <p>Never miss out on your insurance and Pollution Under Control Certificate (PUCC) renewal again! With ParkGO.</p>
                                    </div>
                                </div>

                                <div className='aboutus-content clearfix'>
                                    <div className='aboutus-icon'>
                                        <img class="" src="./assets/about-icon-5.png" alt="" />
                                    </div>
                                    <div className='aboutus-text'>
                                        <p>With ParkGO, finding information regarding RTO services, traffic rules, and more is easier than ever..</p>
                                    </div>
                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </section>
            <section class="parkgo-used">
                <div class="container">
                    <p class="used-heading text-center">
                        Where Can <span>
                            We Used
                        </span> ?
                    </p>
                </div>
                <div class="">
                    <div class="row Used-wrapper justify-content-center gap-5 overflow-hidden m-0">
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Shopping Malls and Buildings.svg" />
                            </div>

                            <p class="used-card-titile">
                                Shopping Malls and Buildings
                            </p>
                            <p class="used-details-text">
                                Can be used widely in malls and building parking areas.

                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Tourists places.svg" />
                            </div>
                            <p class="used-card-titile">
                                Tourists places
                            </p>
                            <p class="used-details-text">
                                Manages parking for tourists and traveling places
                                .
                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Schools and Colleges.svg" />
                            </div>
                            <p class="used-card-titile">
                                Schools and Colleges
                            </p>
                            <p class="used-details-text">
                                Helpful for unmanaged parking in schools and colleges.
                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Corporate building,.svg" />
                            </div>
                            <p class="used-card-titile">
                                Corporate building

                            </p>
                            <p class="used-details-text">
                                Streamlines vehicle parking in corporate offices and buildings.

                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Theaters and Events.svg" />
                            </div>
                            <p class="used-card-titile">
                                Theaters and Events
                            </p>
                            <p class="used-details-text">
                                Ease your parking problems in theaters and event programs
                                .
                            </p>

                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Parks.svg" />
                            </div>
                            <p class="used-card-titile">
                                Parks

                            </p>
                            <p class="used-details-text">
                                Manage the unmanaged parking in parks and playgrounds.

                            </p>
                        </div>
                        <div class="used-cards col-xl-3 col-lg-3 col-md-3 col-sm-6 col-xs-6">
                            <div className='icon-wrapper'>
                                <img src="./assets/Market places.svg" />
                            </div>
                            <p class="used-card-titile">
                                Market places
                            </p>
                            <p class="used-details-text">
                                Useful for crowd management in markets
                                .

                            </p>
                        </div>

                    </div>
                </div>
            </section>
        </div>
    )
}

export default About